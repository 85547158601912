import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";
import reportWebVitals from './reportWebVitals';
import Dashboard from './modules/dashboard';
import Actor from './modules/actors';
import Clients from './modules/clients';
import Login from './modules/auth/Login';

import './style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const appRouting = (
  <ChakraProvider>
    <React.StrictMode>
      <BrowserRouter>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/actors" component={Actor} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/login" component={Login} />
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>
)

ReactDOM.render(appRouting, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
