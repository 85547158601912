import { HStack, Stack, Text } from '@chakra-ui/layout';
import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight } from "react-icons/fi";

export const Counter = props => {

    const { title, count, bgColor } = props;

    return (
        <HStack className="counter" style={{ backgroundColor: bgColor }}>
            <Stack>
                <Text className="title">{title}</Text>
            </Stack>
            <Stack>
                <FiChevronRight className="self-end" fontSize={20} color="#484848" />
            </Stack>
            <Stack>
                <Text className="count">{count}</Text>
            </Stack>
        </HStack>
    )
};

Counter.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    bgColor: PropTypes.string
};