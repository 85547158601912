import React, { useState } from "react";

import DefaultImage from "../assets/default-profile-picture.jpeg";
import Favourited from "../assets/icon-favourite-selected.svg";
import Unfavourited from "../assets/icon-favourite-unselected.svg";

const ActorPreview = ({
  id,
  name,
  surname,
  image,
  selected,
  setSelected,
  isCheckable = true,
}) => {
  const [favourited, setFavourited] = useState(false);

  return (
    <div className="actor-preview-container">
      <div className="header">
        <div className="actor-role-container">
          <p>ACTOR ROLE</p>
        </div>
        <img
          className="favouriteIcon"
          src={favourited ? Favourited : Unfavourited}
          onClick={() => setFavourited(!favourited)}
          alt=""
        />
      </div>
      <div style={{ marginTop: -48 }}>
        <img
          className="actor-image"
          src={image ? image : DefaultImage}
          alt=""
        />
        <div className="actor-preview-detail">
          <div style={{ flexDirection: "column" }}>
            <h1>{name ? name : "NAME"}</h1>
            <h2>{surname ? surname : "SURNAME"}</h2>
          </div>
          {isCheckable && (
            <input
              type="checkbox"
              className="checkbox"
              checked={selected.some((e) => e === id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelected([...selected, id]);
                } else {
                  setSelected(selected.filter((e) => e !== id));
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ActorPreview.defaultProps = {
  name: "NAME",
  surname: "SURNAME",
};

export default ActorPreview;
