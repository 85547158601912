import { Box, Container, Text } from "@chakra-ui/layout";
import {
  Button,
  Divider,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";

import ActorPreview from "../../components/ActorPreview";
import ArrowIcon from "../../assets/icon-arrow-left-large.svg";
import DeleteIcon from "../../assets/icon-delete.svg";
import FavouriteIcon from "../../assets/icon-tab-fave.svg";
import FavouritePopupIcon from "../../assets/icon-favorite.svg";
import Navbar from "../../components/Navbar";
import NewIcon from "../../assets/icon-new.svg";
import PlusIcon from "../../assets/icon-plus.svg";
import SearchIcon from "../../assets/icon-search-filter.svg";
import ShareIcon from "../../assets/icon-share.svg";
import SortIcon from "../../assets/icon-sort.svg";
import UnselectIcon from "../../assets/icon-close-small.svg";
import { actors } from "../../testData";

const Actor = () => {
  const [selected, setSelected] = useState([]);
  return (
    <>
      <Box className="actors-screen">
        <Navbar />
        <>
          <Container w="100vw" maxW="100vw" pr="5" pl="5">
            <HStack justifyContent="space-between" w="100%" flexWrap="wrap">
              <HStack>
                <Image
                  src={ArrowIcon}
                  alignSelf="flex-start"
                  mt="10%"
                  mr="2%"
                />
                <Text
                  m="0"
                  color="#484848"
                  fontSize="10vw"
                  fontFamily="Gothic A1"
                >
                  ACTORS
                </Text>
              </HStack>
              <HStack flexDir="row" justifyContent="space-between">
                <Button
                  leftIcon={<img src={PlusIcon} />}
                  bgColor="black"
                  textColor="white"
                  fontSize="14px"
                  fontFamily="Gothic A1"
                  _hover={{
                    backgroundColor: "black",
                  }}
                >
                  ADD ACTOR
                </Button>
                <Button
                  bgColor="black"
                  textColor="white"
                  fontSize="14px"
                  fontFamily="Gothic A1"
                  _hover={{
                    backgroundColor: "black",
                  }}
                >
                  ACTORS ARCHIVE
                </Button>
              </HStack>
            </HStack>
            <Divider borderColor="#363636" borderWidth="2" />
            <HStack style={{ marginTop: 20 }}>
              <Button
                colorScheme="black"
                variant="link"
                fontSize="20"
                color="#484848"
                fontWeight="bold"
              >
                ALL ACTORS
              </Button>
              <div
                style={{
                  backgroundColor: "#A6A7AD",
                  height: 15,
                  width: 1,
                  marginLeft: 10,
                  marginRight: 5,
                }}
              />
              <Button
                colorScheme="black"
                variant="link"
                fontSize="20"
                color="#757575"
                fontWeight="bold"
                leftIcon={<img src={FavouriteIcon} />}
              >
                FAVOURITES
              </Button>
            </HStack>
            <div className="actors-list-container">
              <div className="actors-list-header">
                <div className="sort-button">
                  <img src={SortIcon} />
                </div>
                <div
                  style={{
                    backgroundColor: "#A6A7AD",
                    height: "60%",
                    width: 1,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                />
                <Select placeholder="Country" w="200">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
                <Select placeholder="Age Range" w="100">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
                <Select placeholder="Gender" w="100">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
                <Select placeholder="Actor Role" w="100">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
                <InputGroup className="searchbox">
                  <Input
                    placeholder="Search"
                    _placeholder={{
                      color: "black",
                    }}
                  />
                  {/*  */}
                  <InputRightElement>
                    <img src={SearchIcon} />
                  </InputRightElement>
                </InputGroup>
              </div>
              <div className="actors-list">
                {actors.map((actor) => (
                  <ActorPreview
                    id={actor.id}
                    name={actor.name}
                    surname={actor.surname}
                    image={actor.image}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
              </div>
            </div>
          </Container>
        </>
      </Box>
      {selected.length > 0 ? (
        <div className="selection-popup">
          <div className="left">
            <p>{selected.length} Actor(s) Selected</p>
            <div className="popup-icon-container">
              <div className="popup-icon">
                <img src={ShareIcon} alt="" />
              </div>
              <div className="popup-icon">
                <img src={NewIcon} alt="" />
              </div>
              <div className="popup-icon">
                <img src={DeleteIcon} alt="" />
              </div>
              <div className="popup-icon">
                <img src={FavouritePopupIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}
            onClick={() => setSelected([])}
          >
            <img src={UnselectIcon} alt="" />
            <p
              style={{
                color: "#A6A7AD",
                fontSize: 12,
                marginLeft: 5,
                fontWeight: "bold",
              }}
            >
              UNSELECT ALL
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Actor;
