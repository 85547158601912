import { Box, VStack } from "@chakra-ui/layout";
import { Button, Container, Image, Input } from "@chakra-ui/react";

import React from "react";
import background from "../../assets/img-login-bg.png";
import logo from "../../assets/logo-login-large.png";
import { useHistory } from "react-router-dom";

const Login = ({ navigation }) => {
  const history = useHistory();
  return (
    <>
      <Image h="100vh" w="100vw" src={background} pos="absolute" />
      <Box
        h="80vh"
        w="45vw"
        bgGradient="linear(to-r, #EEEEEE, #D7D7D7)"
        position="absolute"
        left="28%"
        mt="5%"
        boxShadow="2xl"
      >
        <Container h="100%">
          <VStack ml="50" mr="50" paddingTop="20">
            <Image src={logo} h="118px" w="280px" mb="12" />
            <Container
              h="100"
              flexDir="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Input
                bgColor="white"
                _placeholder={{
                  color: "black",
                  fontWeight: "bold",
                  fontsize: 16,
                }}
                placeholder="Email address"
                marginBottom="2"
                focusBorderColor="gray"
              />
              <Input
                bgColor="white"
                _placeholder={{
                  color: "black",
                  fontWeight: "bold",
                  fontsize: 16,
                }}
                placeholder="Password"
                focusBorderColor="gray"
              />
            </Container>

            <Container>
              <Button
                colorScheme="black"
                variant="link"
                fontSize="12"
                pos="absolute"
                right="100"
              >
                Forgot Password?
              </Button>
            </Container>

            <Button
              bgColor="black"
              textColor="white"
              fontSize="14px"
              w="92%"
              top="50"
              boxShadow="2xl"
              onClick={() => history.push("/")}
            >
              LOGIN
            </Button>
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export default Login;
