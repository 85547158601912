import { FiChevronDown, FiSearch } from "react-icons/fi";
import { HStack, Stack, Text } from "@chakra-ui/layout";
import {
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

import Logo from "../theme/assets/logo-header@2x.png";
import { NavLink } from "react-router-dom";
import ProfilePic from "../theme/assets/profile-pic@2x.png";
import React from "react";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  return (
    <HStack className="navbar" justifyContent="space-between">
      <HStack>
        <Image className="logo" src={Logo} alt="Logo" />
        <ul className="nav-list">
          <NavLink
            className="nav-item"
            activeClassName="text-light-gray"
            to="/dashboard"
          >
            dashboard
          </NavLink>
          <NavLink
            className="nav-item"
            activeClassName="text-light-gray"
            to="/clients"
          >
            Clients
          </NavLink>
          <NavLink
            className="nav-item"
            activeClassName="text-light-gray"
            to="/actors"
          >
            Actors
          </NavLink>
        </ul>
      </HStack>
      <HStack>
        <InputGroup>
          <Input placeholder="Search" className="searchbar" />
          <InputRightElement
            children={<FiSearch fontSize={15} color="#C3C9D0" />}
          />
        </InputGroup>
        <Stack className="notifications">
          <Text>3</Text>
        </Stack>
        <Stack>
          <Menu>
            <MenuButton>
              <HStack>
                <Image src={ProfilePic} alt="profile pic" className="profile" />
                <FiChevronDown fontSize={10} color="#A6A7AD" />
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => history.push("/login")}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </HStack>
    </HStack>
  );
};

export default Navbar;
