import image1 from "./assets/img-actor-1.png";
import image10 from "./assets/img-actor-5.png";
import image2 from "./assets/img-actor-2.png";
import image3 from "./assets/img-actor-3.png";
import image4 from "./assets/img-actor-10.png";
import image5 from "./assets/img-actor-4.png";
import image6 from "./assets/img-actor-9.png";
import image7 from "./assets/img-actor-6.png";
import image8 from "./assets/img-actor-8.png";
import image9 from "./assets/img-actor-7.png";

export const actors = [
  {
    id: 1,
    name: null,
    surname: null,
    image: image1,
  },
  {
    id: 2,
    name: null,
    surname: null,
    image: image2,
  },
  {
    id: 3,
    name: null,
    surname: null,
    image: image3,
  },
  {
    id: 4,
    name: null,
    surname: null,
    image: image4,
  },
  {
    id: 5,
    name: null,
    surname: null,
    image: image5,
  },
  {
    id: 6,
    name: null,
    surname: null,
    image: image6,
  },
  {
    id: 7,
    name: null,
    surname: null,
    image: image7,
  },
  {
    id: 8,
    name: null,
    surname: null,
    image: image8,
  },
  {
    id: 9,
    name: null,
    surname: null,
    image: image9,
  },
  {
    id: 10,
    name: null,
    surname: null,
    image: image10,
  },
  {
    id: 11,
    name: null,
    surname: null,
    image: image1,
  },
  {
    id: 12,
    name: null,
    surname: null,
    image: image2,
  },
];
