import { HStack, Stack } from '@chakra-ui/layout';
import { Button, Text } from '@chakra-ui/react'
import React, { useState } from 'react';
import { ListItem } from '../../components/Card';
import Navbar from '../../components/Navbar';
import { Counter } from '../../components/Statistic';
import { PageTitle, TitleDivider } from '../../components/Title';
import { FiUserPlus, FiFilePlus, FiChevronRight } from "react-icons/fi";
import ActorPreview from '../../components/ActorPreview';
import { actors } from "../../testData";

const Dashboard = () => {

    const [casts] = useState([
        {
            "title": "0.0% Now You Can",
            "brand": "Heineken",
            "cast": "Commercial",
            "status": "casting open",
        },
        {
            "title": "Galaxy Note20 Ultra",
            "brand": "Heineken",
            "cast": "Samsung",
            "status": "casting open",
        },
        {
            "title": "The Powerful 2021 Hilux",
            "brand": "Heineken",
            "cast": "Toyota",
            "status": "casting open",
        },
        {
            "title": "The Story",
            "brand": "Roadhouse",
            "cast": "Commercial",
            "status": "Complete",
        },
    ])

    return (
        <div className="primary-bg">
            <Navbar />

            <div className="container-fluid pb-5">
                <div className="row">
                    <div className="col-8">
                        <PageTitle title="projects" />

                        <Stack>
                            <TitleDivider title="you’ve recently opened" />
                            {
                                casts?.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        {...item}
                                    />
                                ))
                            }
                        </Stack>

                        <Stack>
                            <TitleDivider title="Recently Completed" />
                            {
                                casts?.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        {...item}
                                    />
                                ))
                            }
                        </Stack>
                    </div>
                    <div className="col-4 px-0 secondary-bg">
                        <Stack bg="#FFFFFF" p={6} style={{ minHeight: 160 }}>
                            <Stack spacing={4}>
                                <Button leftIcon={<FiUserPlus fontSize={20} color="#FFFFFF" />} bg="#000000" color="#FFFFFF" variant='solid'>
                                    ADD NEW CLIENT
                                </Button>
                                <Button leftIcon={<FiFilePlus fontSize={20} color="#FFFFFF" />} bg="#000000" color="#FFFFFF" variant='solid'>
                                    START NEW PROJECT
                                </Button>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Counter
                                title="clients"
                                count="07"
                                bgColor="#B5B4B4"
                            />
                            <Counter
                                title="actors"
                                count="36"
                                bgColor="#CBCBCB"
                            />
                        </Stack>
                        <Stack p={8}>
                            <Stack>
                                <HStack mb={4}>
                                    <Text color="#484848" fontSize={16} fontWeight="bold">Recent Favourites</Text>
                                    <FiChevronRight fontSize={20} color="#484848" />
                                </HStack>
                                <HStack className="overflow-scroll">
                                {
                                    actors?.map(actor => (
                                    <ActorPreview
                                        key={actor.id}
                                        isCheckable={false}
                                        {...actor}
                                    />
                                    ))
                                }
                                </HStack>
                            </Stack>
                        </Stack>
                        <Stack p={8}>
                            <Stack>
                                <HStack mb={4}>
                                    <Text color="#484848" fontSize={16} fontWeight="bold">Most Cast Actors</Text>
                                    <FiChevronRight fontSize={20} color="#484848" />
                                </HStack>
                                <HStack className="overflow-scroll">
                                {
                                    actors?.map(actor => (
                                    <ActorPreview
                                        key={actor.id}
                                        isCheckable={false}
                                        {...actor}
                                    />
                                    ))
                                }
                                </HStack>
                            </Stack>
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;