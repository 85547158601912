import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Stack, Text } from '@chakra-ui/layout';
import { FiChevronRight } from "react-icons/fi";

export const ListItem = props => {

    const { title, brand, cast, status } = props;

    return (
        <div className="card-list-item">
            <HStack justifyContent="space-between">
                <Stack>
                    <h5 className="title">{title}</h5>
                    <HStack>
                        <Text className="brand">{brand}</Text>
                        <span>|</span>
                        <Text className="cast">{cast}</Text>
                    </HStack>
                </Stack>
                <Stack>
                    <Stack className="status-bg">
                        <Text className="status">{status}</Text>
                    </Stack>
                    <FiChevronRight className="self-end" fontSize={16} color="#A6A7AB" style={{ marginTop: 12 }} />
                </Stack>
            </HStack>
        </div>
    )
};

ListItem.propTypes = {
    title: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    cast: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};