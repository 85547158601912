import { Text } from '@chakra-ui/layout';
import React from 'react';
import Navbar from '../../components/Navbar';

const Clients = () => {
    return (
        <>
            <Navbar />
            <Text>Clients</Text>
        </>
    )
}

export default Clients;