import React from 'react';
import PropTypes from 'prop-types';

export const PageTitle = ({ title }) => {
    return (
        <>
            <h1 className="page-title">{title}</h1>
        </>
    )
};

PageTitle.propTypes = {
    title: PropTypes.string.isRequired
};

export const TitleDivider = ({ title }) => {
    return (
        <div className="title-divider">
            <p>{title}</p>
            <hr />
        </div>
    )
};

TitleDivider.propTypes = {
    title: PropTypes.string.isRequired
};